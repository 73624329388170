// @flow
import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	MISSING: {
		value: false,
		name: 'Missing',
		label: 'Missing',
		description: 'One or more bill items are missing a chart of accounts.',
		color: '#A73221',
		backgroundColor: '#F9E5E2',
	},
	COMPLETE: {
		value: true,
		name: 'Complete',
		label: 'Complete',
		description: 'All bill items have a chart of accounts assigned.',
		color: '#1E6636',
		backgroundColor: '#DFF5E7',
	},
})
